.results {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  height: 100dvh;

  min-height: -webkit-fill-available;
  // padding: 0 1rem 1rem 1rem;
  padding: 1rem;
}
.results-room-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.results-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  gap: 0.3rem;
}

.results__sentence-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*   border: 1px solid white; */
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  line-height: 2.3;
}
.results__sentence {
  max-width: 400px;
  line-height: 2.6;
}

.results-button {
  display: flex;
  height: 2rem;
  width: 7rem;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;
  margin-left: 0.2rem;
  border: 2px solid white;
}

.results__vote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
  gap: 1rem;
  font-size: large;
}
#voteIcons {
  width: 8rem;
  margin: 0 auto;
  // padding-top: 100px;
  padding: 0.3rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  gap: 0.1rem;
}

$blue: #3f1fcf;
$green: #37b007;
$lightGreen: #a6e300;
$yellow: #e8dc00;
$orange: #e07f00;
$red: #de2904;

.fa-laugh {
  color: $green;
  height: 1.3rem;
}

.fa-smile {
  color: $lightGreen;
  height: 1.3rem;
}

.fa-meh {
  color: $yellow;
  height: 1.3rem;
}

.fa-frown {
  color: $orange;
  height: 1.3rem;
}

.fa-dizzy {
  color: $red;
  height: 1.3rem;
}
.results__bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 1rem;
  padding-top: 1rem;
}

.results-button {
  display: flex;

  height: 2.6rem;
  width: 9rem;
  font-size: 1rem;
}
.results__bottom-info {
  height: 1rem;
  width: 100%;
  font-size: 1rem;
}

//tooltip
.mytooltip {
  display: inline;
  position: relative;
  z-index: 999;
  overflow: hidden;
}

.mytooltip .tooltip-item {
  cursor: pointer;
  display: inline-block;
}

.mytooltip .tooltip-content {
  position: absolute;
  z-index: 9999;
  width: 11rem;
  left: 0%;
  bottom: 100%;
  // text-align: center;
  font-size: 1rem;
  line-height: 1;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10rem;
  display: flex;
  align-items: center;
  opacity: 0;
  cursor: default;
  pointer-events: none;
  overflow: hidden;
}

.mytooltip .tooltip-content::after {
  content: '';
  top: 100%;
  left: 50%;

  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent;
  border-width: 0.625rem;
  margin-left: -0.625rem;
}

.mytooltip .tooltip-content img {
  position: relative;
  display: block;
  max-width: 3.5rem;
}

.mytooltip .tooltip-item::after {
  content: '';
  position: absolute;
  width: 22.5rem;
  height: 1.25rem;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  transform: translateX(-50%);
}

.mytooltip:hover .tooltip-item::after {
  pointer-events: auto;
}

.mytooltip:hover .tooltip-content {
  pointer-events: auto;

  opacity: 1;
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
}

.mytooltip .tooltip-text {
  display: flex;
  padding: 1rem 1rem 1rem 1rem;
  flex: 1;

  justify-content: center;
}

@media only screen and (min-width: 750px) {
  .results {
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-width: 500px;
    max-height: 700px;
  }
  .results__sentence {
    max-width: 550px;
    line-height: 2.3;
    font-size: 1.2rem;
  }
  .results-container {
    justify-content: space-around;
  }
}
