.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-container {
  background-color: #ffffff;
  max-width: 80%;
  padding: 20px;
  border-radius: 8px;
}

.modal-content {
  color: rgb(75 85 99);
}
